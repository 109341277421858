import * as React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ShutterstockPhotoContactUs from '../images/uploads/Testimonials_shutterstock_1179932260.jpg';

const ContactUs = () => {
  const formId = 'xrgrdrvd';
  const [state, handleSubmit] = useForm(formId);
  return (
    <Layout pageSlug="contact-us">
      <SEO title="Contact Us" />
      <div className="row bg-grey bg-marque">
        <section className="row header">
          <div className="container">
            <div className="row content">
              <div className="col l24 m24 s24">
                <div className="box bs header-full-image">
                  <div className="page-heading">
                    <h3>Contact Us</h3>
                  </div>
                  <div className="inner-margin-top-minus-60">
                    <img src={ShutterstockPhotoContactUs} alt="Contact Us" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row">
          <div className="container">
            <div className="row">
              <div className="col l12 m16 s24">
                <div className="box bs">
                  <h2>Get in touch</h2>
                </div>
              </div>
            </div>
            <div className="row padding-bottom-50">
              <div className="col l12 m16 s24 offset-l2 offset-m2 offset-s0">
                <div className="box bs content-block">
                  <p>You can get in touch with us using the details below:</p>
                  <p>
                    <strong>Telephone:</strong>&nbsp;+44 (0) 1753 861657
                    <br />
                    <strong>Email:</strong>
                    <a href="mailto:info@121payments.com">
                      info@121payments.com
                    </a>
                  </p>
                  <p>
                    <strong>Post:</strong>
                    <br />
                    121 Student Payments Limited,
                    <br />
                    Duke&rsquo;s House, 4 High Street,
                    <br />
                    Windsor, Berks, UK, SL4 1LD
                  </p>
                  <p>
                    121 Student Payments Limited | Registered Company No:
                    08617365.
                  </p>
                </div>
              </div>
              <div className="col l12 m16 s24 offset-l2 offset-m2 offset-s0">
                {state.succeeded ? (
                  <>
                    <div className="box bs content-block">
                      <p>
                        Thanks for your submission, we will get back to you
                        shortly
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="box bs">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <label htmlFor="firstname">First Name:</label>
                          <input
                            id="firstname"
                            type="text"
                            className="form-control"
                            name="firstname"
                            required
                          />
                          <ValidationError
                            prefix="First Name"
                            field="firstname"
                            errors={state.errors}
                          />
                        </div>

                        <div className="row">
                          <label htmlFor="lastname">
                            Family Name / Surname:
                          </label>
                          <input
                            id="lastname"
                            type="text"
                            className="form-control"
                            name="lastname"
                            required
                          />
                          <ValidationError
                            prefix="Last Name"
                            field="lastname"
                            errors={state.errors}
                          />
                        </div>

                        <div className="row">
                          <label htmlFor="email">Email Address:</label>
                          <input
                            id="email"
                            className="form-control"
                            type="email"
                            name="email"
                            required
                          />
                          <ValidationError
                            prefix="Email"
                            field="email"
                            errors={state.errors}
                          />
                        </div>

                        <div className="row">
                          <label htmlFor="phone">Phone Number:</label>
                          <input
                            id="phone"
                            className="form-control"
                            type="tel"
                            name="phone"
                          />
                          <ValidationError
                            prefix="phone"
                            field="phone"
                            errors={state.errors}
                          />
                        </div>

                        <div className="row">
                          <label htmlFor="group">I am a...</label>
                          <select name="group">
                            <option value="Student" selected>
                              Student
                            </option>
                            <option value="Parent">Parent</option>
                            <option value="Guardian">Guardian</option>
                          </select>
                          <ValidationError
                            prefix="Group"
                            field="group"
                            errors={state.errors}
                          />
                        </div>

                        <div className="row">
                          <label htmlFor="country">
                            Which is your Home Country?:
                          </label>
                          <select
                            id="country"
                            name="country"
                            data-cgbf-selected="United Kingdom"
                          >
                            <option value="Afganistan">Afghanistan</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">
                              American Samoa
                            </option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antigua &amp; Barbuda">
                              Antigua &amp; Barbuda
                            </option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bonaire">Bonaire</option>
                            <option value="Bosnia &amp; Herzegovina">
                              Bosnia &amp; Herzegovina
                            </option>
                            <option value="Botswana">Botswana</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Ter">
                              British Indian Ocean Ter
                            </option>
                            <option value="Brunei">Brunei</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Canary Islands">
                              Canary Islands
                            </option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">
                              Cayman Islands
                            </option>
                            <option value="Central African Republic">
                              Central African Republic
                            </option>
                            <option value="Chad">Chad</option>
                            <option value="Channel Islands">
                              Channel Islands
                            </option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">
                              Christmas Island
                            </option>
                            <option value="Cocos Island">Cocos Island</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote DIvoire">Cote DIvoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Curaco">Curacao</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">
                              Czech Republic
                            </option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">
                              Dominican Republic
                            </option>
                            <option value="East Timor">East Timor</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">
                              Equatorial Guinea
                            </option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands">
                              Falkland Islands
                            </option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">
                              French Polynesia
                            </option>
                            <option value="French Southern Ter">
                              French Southern Ter
                            </option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Great Britain">Great Britain</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="India">India</option>
                            <option value="Iran">Iran</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea North">Korea North</option>
                            <option value="Korea Sout">Korea South</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Laos">Laos</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">
                              Marshall Islands
                            </option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Midway Islands">
                              Midway Islands
                            </option>
                            <option value="Moldova">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Nambia">Nambia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherland Antilles">
                              Netherland Antilles
                            </option>
                            <option value="Netherlands">
                              Netherlands (Holland, Europe)
                            </option>
                            <option value="Nevis">Nevis</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">
                              Norfolk Island
                            </option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau Island">Palau Island</option>
                            <option value="Palestine">Palestine</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">
                              Papua New Guinea
                            </option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Phillipines">Philippines</option>
                            <option value="Pitcairn Island">
                              Pitcairn Island
                            </option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Republic of Montenegro">
                              Republic of Montenegro
                            </option>
                            <option value="Republic of Serbia">
                              Republic of Serbia
                            </option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="St Barthelemy">St Barthelemy</option>
                            <option value="St Eustatius">St Eustatius</option>
                            <option value="St Helena">St Helena</option>
                            <option value="St Kitts-Nevis">
                              St Kitts-Nevis
                            </option>
                            <option value="St Lucia">St Lucia</option>
                            <option value="St Maarten">St Maarten</option>
                            <option value="St Pierre &amp; Miquelon">
                              St Pierre &amp; Miquelon
                            </option>
                            <option value="St Vincent &amp; Grenadines">
                              St Vincent &amp; Grenadines
                            </option>
                            <option value="Saipan">Saipan</option>
                            <option value="Samoa">Samoa</option>
                            <option value="Samoa American">
                              Samoa American
                            </option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome &amp; Principe">
                              Sao Tome &amp; Principe
                            </option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">
                              Solomon Islands
                            </option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Tahiti">Tahiti</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad &amp; Tobago">
                              Trinidad &amp; Tobago
                            </option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks &amp; Caicos Is">
                              Turks &amp; Caicos Is
                            </option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="United Kingdom" selected>
                              United Kingdom
                            </option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Erimates">
                              United Arab Emirates
                            </option>
                            <option value="United States of America">
                              United States of America
                            </option>
                            <option value="Uraguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican City State">
                              Vatican City State
                            </option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vietnam">Vietnam</option>
                            <option value="Virgin Islands (Brit)">
                              Virgin Islands (Brit)
                            </option>
                            <option value="Virgin Islands (USA)">
                              Virgin Islands (USA)
                            </option>
                            <option value="Wake Island">Wake Island</option>
                            <option value="Wallis &amp; Futana Is">
                              Wallis &amp; Futana Is
                            </option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zaire">Zaire</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                          <ValidationError
                            prefix="Country"
                            field="country"
                            errors={state.errors}
                          />
                        </div>

                        <div className="row">
                          <label htmlFor="message">Message:</label>
                          <textarea
                            id="message"
                            className="form-control"
                            name="message"
                            required
                          ></textarea>
                          <ValidationError
                            prefix="Message"
                            field="message"
                            errors={state.errors}
                          />
                        </div>

                        <div className="row">
                          <button
                            className="btn btn-primary animated-arrow"
                            type="submit"
                            disabled={state.submitting}
                          >
                            <span className="the-arrow -left">
                              <span className="shaft"></span>
                            </span>
                            <span className="main">
                              <span className="text"> Get in touch </span>
                              <span className="the-arrow -right">
                                <span className="shaft"></span>
                              </span>
                            </span>
                          </button>
                        </div>
                        <input
                          type="hidden"
                          name="site"
                          value="payments2study"
                        />
                      </form>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ContactUs;
